import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import { SubtitleCSS, Heading2CSS, MainBodyCSS } from '../styles/typography'
import { breakpoints } from '../styles/breakpoints'

import Layout from '../components/layout/layout'
import Wrapper from '../components/wrapper/wrapper'

const Content = styled.div`
  margin: auto;
  max-width: 600px;
  text-align: center;
`

const Subtitle = styled.span`
  ${SubtitleCSS}
  color: #808080;
  display: inline-block;
  margin-bottom: 16px;
`

const H2 = styled.h2`
  ${Heading2CSS}
  margin-bottom: 24px;
  text-align: center;

  @media ${breakpoints.laptop} {
    margin-bottom: 48px;
  }
`

const Body = styled.p`
  ${MainBodyCSS}
`

export default function NotFound() {
  return (
    <Layout>
      <Wrapper>
        <Content>
          <Subtitle>404</Subtitle>
          <H2>Page Not Found</H2>
          <Body>
            Sorry we can't find that page!<br />
            <br />
            <Link to={`/`}>
              Back to Portfolio
            </Link>
          </Body>
        </Content>
      </Wrapper>
    </Layout>
  )
}
